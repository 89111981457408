import {
  deleteProjectMembers,
  getProjectAndSet,
  setProjectInviteModal,
  updateProjectMembers,
} from '@/states/actions'
import { RootState } from '@/states/reducers'
import { useAppDispatch } from '@/states/store'
import { DeleteFilled, ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Col, Input, message, Modal, Row, Select, Table } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { ProjectsInviteModal } from './Projects.invite.modal'

/**
 * 프로젝트 모델 목록 보기
 * @param param0
 * @returns
 */
export const ProjectsViewMembers = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { projectsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { currentProject } = projectsState

  // State
  const [keyword, setKeyword] = useState<string>('')

  // Table columns
  const tableCols = [
    {
      title: t('name'),
      key: 'name',
      render: (member) => (
        <div className={'flex items-center space-x-3'}>
          {member?.image && member?.image?.path ? (
            <div
              className={`w-8 h-8 rounded-full bg-gray-300 flex-none bg-cover bg-center`}
              style={{ backgroundImage: `url(${member?.image?.path})` }}></div>
          ) : (
            <div
              className={`w-8 h-8 rounded-full bg-gray-300 flex-none memex-ico-user`}></div>
          )}
          <div className={'grow overflow-hidden'}>
            <div className={'leading-5 truncate'}>{member?.name}</div>
            <div className={'text-xs text-gray-500 leading-4 -mt-0.5 truncate'}>
              {member?.email}
            </div>
          </div>
        </div>
      ),
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    /* {
                              title: t('editedDate'),
                              key: 'editedAt',
                              render: (member) => (
                                <>
                                  {member.date.editedAt
                                    ? moment(member.date.editedAt, 'YYYYMMDDHHmmss').fromNow()
                                    : '-'}
                                </>
                              ),
                              sorter: (a, b) => a.date.editedAt - b.date.editedAt,
                            }, */
    {
      title: t('role'),
      key: 'actionsRole',
      render: (member) => (
        <Select
          onChange={(val) => onRoleEdit(member, val)}
          style={{
            width: 118,
          }}
          dropdownMatchSelectWidth={200}
          bordered={false}
          value={member.role}>
          {currentProject?.availableRoles?.map((role) => (
            <Select.Option key={role}>
              <div>
                <div>{t('roleDesc.' + role.toLocaleLowerCase() + '.name')}</div>
                <div className={'text-xs text-gray-500 overflow-visible'}>
                  {t('roleDesc.' + role.toLocaleLowerCase() + '.desc')}
                </div>
              </div>
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: '',
      key: 'actionsDelete',
      align: 'right',
      render: (member) => (
        <Button
          type={'text'}
          icon={<DeleteFilled></DeleteFilled>}
          onClick={() => onMemberDelete(member)}></Button>
      ),
    },
  ]

  /**
   * 권한 수정
   * @param member
   * @param val
   */
  const onRoleEdit = (member, val) => {
    Modal.confirm({
      centered: true,
      title: t('confirmChangeRoleTitle'),
      icon: <ExclamationCircleOutlined />,
      content: t('confirmChangeRoleDesc'),
      okText: t('change'),
      cancelText: t('cancel'),
      onOk() {
        return new Promise((resolve, reject) => {
          if (currentProject) {
            const updatedMemberRoleList = [
              ...currentProject.memberList
                .filter((ma) => member.id !== ma.id)
                .map((ma) => {
                  return { memberId: ma.id, role: ma.role }
                }),
              {
                memberId: member.id,
                role: val,
              },
            ]

            // 관리자 최소 인원 확인
            if (!updatedMemberRoleList.find((m) => m.role === 'ADMIN')) {
              message.warn(t('needAtLeastOneAdmin'))
            } else {
              const req = [
                {
                  memberId: member.id,
                  role: val,
                },
              ]

              updateProjectMembers(currentProject.uid, req)
                .then(async (res) => {
                  await resolve(dispatch(getProjectAndSet(currentProject.uid)))
                  await message.success(t('memberRoleUpdated'))
                })
                .catch((e) => {
                  message.error(e.response.data.error)
                })
            }
          }
        }).catch((e) => console.log(e))
      },
      onCancel() {},
    })
  }

  /**
   * 멤버 제거
   * @param member
   */
  const onMemberDelete = (member) => {
    Modal.confirm({
      centered: true,
      title: t('confirmDeleteMemberTitle'),
      icon: <ExclamationCircleOutlined />,
      content: t('confirmDeleteMemberDesc'),
      okText: t('delete'),
      cancelText: t('cancel'),
      onOk() {
        return new Promise((resolve, reject) => {
          if (currentProject) {
            deleteProjectMembers(currentProject?.uid, [member.id]).then(
              async (res) => {
                const updatedProject = currentProject
                updatedProject.memberList = currentProject?.memberList?.filter(
                  (ma) => member.id !== ma.id
                )

                await resolve(dispatch(getProjectAndSet(currentProject.uid)))
                await resolve(res)
                message.info(t('membersRemoved'))
              }
            )
          }
        }).catch((e) => console.log(e))
      },
      onCancel() {},
    })
  }

  return (
    <div className={'space-y-10'}>
      {/* Setting tools: 시작 */}
      <div>
        <Row gutter={24}>
          <Col span={18}>
            <Input.Search
              size={'middle'}
              enterButton
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              placeholder={t('searchMemberByEmailOrName')}></Input.Search>
          </Col>
          <Col span={6}>
            <Button
              type={'primary'}
              block
              onClick={() => dispatch(setProjectInviteModal(true))}>
              {t('inviteMembers')}
            </Button>
          </Col>
        </Row>
      </div>
      {/* Setting tools: 끝 */}
      {/* Setting body: 시작 */}
      <div>
        <div>
          <Table
            // @ts-ignore
            columns={tableCols}
            dataSource={currentProject?.memberList
              ?.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i)
              .filter(
                (m) =>
                  !keyword ||
                  m.name.includes(keyword) ||
                  m.email.includes(keyword)
              )}
            rowKey="id"
            pagination={false}></Table>
        </div>
      </div>
      {/* Setting body: 끝 */}
      <ProjectsInviteModal />
    </div>
  )
}
